import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";
import { useDaoVotes } from "@/src/hooks/useVotes";

export const DaoVoteCard = () => {
  const { chain } = useAccount();
  const { onVote, onProposeChange, onExecuteVote, onCancelDaoVote, onDaoVote } = useStake();
  const { uncharged,charged, frbBalance } = useBalances();
  const { address, isConnected } = useAccount();
  const { eligibleVotes, vote_enabled, functionCalled, deadline, var1, var2, var3, var4, voteAmount } = useDaoVotes();
  const submitButtonRef = useRef(null);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });
  const [{ _var1, _var2, _var3, _var4 }, setInput] = useState({
    _var1: 0,
    _var2: 0,
    _var3: "",
    _var4: "",
  });

  const onInputVar1 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var1: value
    }));
  };
  const onInputVar2 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var2: value
    }));
  };
  const onInputVar3 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var3: value
    })); 
  }; 
  const onInputVar4 = (event) => {
    const value = event.target.value;
    setInput(prevState => ({
      ...prevState,
      _var4: value
    })); 
  }; 

  const onChange = async (e) => {
    if (!isConnected) return;

    console.log(_var1);
    console.log(_var2);

    toast.promise(onProposeChange(e.currentTarget.id, _var1 , _var2, _var3, _var4), {
      loading: "Transaction is in progress",
      success: "Successfully proposed change",
      error: (data) => data,
    });
  }

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const voteYes = async (event) => {
    event.preventDefault();
      return toast.promise(onDaoVote(1), {
        loading: "Transaction is in progress",
        success: "Successfully voted Yes",
        error: (data) => data,
      });
  };
  const voteNo = async (event) => {
    event.preventDefault();
      return toast.promise(onDaoVote(0), {
        loading: "Transaction is in progress",
        success: "Successfully voted No",
        error: (data) => data,
      });
  };
  return (
    <div>
    <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
      {vote_enabled &&
      <>
      <div className="py-1">
        <b>FUNCTION CALLED: {functionCalled}</b>
      </div>
      {  var1 != 0 && <h2>Parameter 1: {parseFloat(var1).toFixed(0)}</h2>}
      {  var2 != 0 && <h2>Parameter 2: {parseFloat(var2).toFixed(0)}</h2>}
      {  var3 != "0x0000000000000000000000000000000000000000" && <h2>Parameter 3: {var3}</h2> }
      {  var3 != "0x0000000000000000000000000000000000000000" && <h2>Parameter 4: {var4}</h2> }
      </>
      }
      
      <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Eligible votes:</b> {parseInt(eligibleVotes)}</p>
           
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
          <p><b>Voting:</b> {(vote_enabled && Date.now()/1000 < (parseInt(deadline))) && "Open" || "Closed"}</p>
        </div>
        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
      </div>
      <form onSubmit={voteYes}>
          <div className="flex justify-center py-2 text-foreground/50">
          {isConnected && <Button type="submit" value="1" disabled={!isCorrectChain ||eligibleVotes==0 || vote_enabled==false } >Yes</Button>}
          </div>
          </form> 
      <form onSubmit={voteNo}>
        <div className="flex justify-center py-2 text-foreground/50">
          {isConnected && <Button type="submit" value="0" disabled={!isCorrectChain || eligibleVotes==0 || vote_enabled==false } >No</Button>}
          </div>
      </form>
    </div>
    <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
    <div className="py-1">
        <h2>{parseFloat(voteAmount).toFixed(0)} FRB and minimum 1 NFT is required to propose a change. This can be refunded when voted by the community.
        </h2>
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <b>Transfer FRB</b>
      </div>
        </div>
        <div className="py-1">
            <input
              onChange={onInputVar1}
              placeholder="Amount of FRB (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="transferFRB" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <b>Transfer LP Tokens</b>
      </div>
        </div>
        <div className="py-1">
            <input
              onChange={onInputVar1}
              placeholder="Amount of LP Tokens (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="transferLp" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <b>Transfer ERC20 Tokens</b>
      </div>
        </div>
        <div className="py-1">
            <input
              onChange={onInputVar1}
              placeholder="Amount of ERC20 Tokens (WEI format)"
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder=" Destination Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
            <div className="py-1">
        <input
              onChange={onInputVar4}
              placeholder="ERC20 Token Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="transferERC20" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Change owner of Airdrop Contract</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="airdropTransferOwnership" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Change owner of Rewards Contract</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="rewardsTransferOwnership" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Change owner of Token Contract</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="frbTransferOwnership" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>
      <div className="flex items-center h-full">
    <div className="py-1">
    <div className="py-1"> <b>Migrate FRB, LP & contracts to new DAO contract</b></div>
      </div>
      </div>
        <div className="py-1">
        <input
              onChange={onInputVar3}
              placeholder="Contract Address 0x..."
              className="text-foreground font-medium w-full rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="text"
              required
            />
            </div>
        <div className="flex items-center h-full">
        <Button id="migrate" onClick={onChange} className="" disabled={vote_enabled==true || (parseInt(uncharged)+parseInt(charged)) == 0 || parseInt(frbBalance) < parseFloat(voteAmount).toFixed(0)}>
          <h2 className="md:text-xl text-xl text-foreground">Propose Change</h2>
        </Button>
        {}
      </div>

   </div>
      
    </div>
    
  );
};
