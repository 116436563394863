import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { PendingRewards } from ".";

export const RewardBalance = () => {
  const { rewardPoints, harvestMinimum, harvestRate, pendingRewards } = useBalances();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Reward Overview</h2>
          </span>
          <div>
            
          </div>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(rewardPoints).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Harvest Points</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(harvestMinimum).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Minimum Staked FRB</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(harvestRate).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Points earned each harvest (daily)</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(pendingRewards).toFixed(0)} FRB
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Pending Rewards </h2>
        </div>
      </div>
    </Card>
    
    
  );
};
