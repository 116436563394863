import canyDaoABI from "@/src/assets/FlareCanaryFarm.json";
import FlareReborn from "@/src/assets/FlareReborn.json";
import nftABI from "@/src/assets/FlareHarvestNft.json";
import rewardsABI from "@/src/assets/FlareRewards.json";
import farm from "@/src/assets/FlareFarm.json";
import vote from "@/src/assets/RebornVote.json";
import daoReborn from "@/src/assets/DaoReborn.json";
import daoVote from "@/src/assets/DaoVote.json";
import airdrop from "@/src/assets/airdrop.json";

export const CANARY_DAO_ABI = canyDaoABI;
export const ERC20_ABI = FlareReborn;
export const NFT_ABI = nftABI;
export const LP_ABI = FlareReborn;
export const REWARD_ABI = rewardsABI;
export const FARM_ABI = farm;
export const VOTE_ABI = vote;
export const REBORN_DAO_ABI = daoReborn;
export const DAO_VOTE_ABI = daoVote;
export const AIRDROP_ABI = airdrop;

export const DAO_CONTRACT_ADDRESS =
  "0x6292813Ee6776Ead53E2697054356e1d9f9e8db5";
export const ERC20_CONTRACT_ADDRESS =
  "0xAAF2eEa8D701EA7d6d328C6a60c4032C9c301EE6";
export const NFT_CONTRACT_ADDRESS =
"0xD301a72D52423F826EA74545b817b448F5B8f877";
export const LP_CONTRACT_ADDRESS =
"0xda7912E776e7Af0AEab95131Dee0B2d8D8AC60eB";
export const REWARD_CONTRACT_ADDRESS =
"0x662d11384fc66b72cBbebB27a55A573C9e0Abce0";
export const FARM_ADDRESS =
"0x6292813Ee6776Ead53E2697054356e1d9f9e8db5";
export const VOTE_ADDRESS = 
"0x95e818453d132eC6916bF030677b093F7fcC5780";
export const DAO_REBORN_ADDRESS =
"0x70EF8ba16a0AF111CC6AB510349529203dfb0678";
export const DAO_VOTE_ADDRESS = 
"0x73eB6398a9BD854afE2d914990d1D58F5445C56d";
export const AIRDROP_ADDRESS = 
"0xD6322B7a1e6Ad30E8C52b7178Dc8cD32b2e8Dbe5";

