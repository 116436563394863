import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";
import { useAirdrop } from "@/src/hooks";
import { Timer } from "@/src/components/deadlineTimer";

export const PendingAirdrop = () => {
  const { airdropAmount, nextClaim, totalAmount, withdrawn } = useAirdrop();
  const { frbStakedBalance } = useBalances();
  const { onClaimAirdrop } = useStake();
  const { isConnected } = useAccount();

  const handleRewards = async () => {
    if (!isConnected) return;

    toast.promise(onClaimAirdrop(), {
      loading: "Transaction is in progress",
      success: "Successfully claimed airdrop",
      error: (data) => data,
    });
  }
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(withdrawn) > parseFloat(frbStakedBalance) && <h2 className="text-xl font-semibold text-foreground/50">**Stake additional {(parseFloat(withdrawn)-parseFloat(frbStakedBalance)).toFixed(2)} FRB to claim 100%**</h2>}
          {parseFloat(airdropAmount).toFixed(2)} FRB
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Next Airdrop Amount</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseInt(nextClaim) > 0 ? <Timer time={parseInt(nextClaim)}/> : "00:00:00:00"}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Next Claim</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(totalAmount).toFixed(2)} FRB
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Total Pending Airdrop</h2>
        </div>
        <div className="flex items-center h-full">
        <Button onClick={handleRewards} className="" disabled={parseFloat(airdropAmount)==0 || parseFloat(nextClaim) > Date.now()/1000 }>
          <h2 className="md:text-xl text-xl text-foreground">Claim Airdrop</h2>
        </Button>
        {}
      </div>
      </div>
    </Card>
    
  );
};
