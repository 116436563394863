// songbird configurations
export const activeChain = {
  chainId: "0xE", // must be hexadecimal for chainId : 0x10 = 16
  chainName: "Flare Mainnet",
  nativeCurrency: {
    symbol: "FLR",
    decimals: 18,
  },
  blockExplorerUrls: ["https://flare-explorer.flare.network/"],
  rpcUrls: ["https://flare-api.flare.network/ext/bc/C/rpc"],
};
