import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";
import { useDaoVotes } from "@/src/hooks/useVotes";

export const Stake = () => {
  const { chain } = useAccount();
  const { onStake, onUnstake } = useStake();
  const { address, isConnected } = useAccount();
  const { balance, stakeBalance } = useBalances();
  const { eligibleVotes, vote_enabled, deadline } = useDaoVotes();
  const submitButtonRef = useRef(null);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });
  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const [{ isStake, placeholder }, setOption] = useState({
    isStake: true,
    placeholder: "Amount to stake",
  });

  const [{ stakeInput, unstakeInput }, setInput] = useState({
    stakeInput: "",
    unstakeInput: "",
  });
  useEffect(() => {
    submitButtonRef.current &&
      (() => {
        submitButtonRef.current.disabled = true;
        if (!isCorrectChain) {
          submitButtonRef.current.innerHTML = "Wrong Network";
          return;
        }

        if (isStake) {
          if (balance == 0 || stakeInput > balance) {
            submitButtonRef.current.innerHTML = "insufficient balance";
            return;
          }
          if (stakeInput) {
            submitButtonRef.current.innerHTML = "Stake";
            submitButtonRef.current.disabled = false;
            return;
          }
          submitButtonRef.current.innerHTML = "Enter quantity";

          return;
        }

        if (stakeBalance == 0 || unstakeInput > stakeBalance) {
          submitButtonRef.current.innerHTML = "insufficient balance";
          submitButtonRef.current.disabled = true;
          return;
        }
        if (unstakeInput) {
          submitButtonRef.current.innerHTML = "Unstake (5% LP FEE)";
          submitButtonRef.current.disabled = false;
          return;
        }
        submitButtonRef.current.innerHTML = "Enter quantity";
          return;
      })();
  }, [isStake, stakeInput, unstakeInput, balance, stakeBalance]);

  const onInputChange = (event) => {
    const value = event.target.valueAsNumber;
    const _stakeInput = isStake ? value : stakeInput;
    const _unstakeInput = isStake ? unstakeInput : value;
    setInput({
      stakeInput: _stakeInput,
      unstakeInput: _unstakeInput,
    });
  };

  const onUnstakeInput = () => {
    setOption({
      isStake: false,
      placeholder: "Amount to unstake",
    });
  };
  const onStakeInput = () => {
    setOption({
      isStake: true,
      placeholder: "Amount to stake",
    });
  };

  const onMaxBalance = () => {
    if (isStake)
      return setInput((prev) => ({
        ...prev,
        stakeInput: balance,
      }));
    setInput((prev) => ({
      ...prev,
      unstakeInput: stakeBalance,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isStake)
      return toast.promise(onStake(stakeInput), {
        loading: "Transaction is in progress",
        success: "Successfully staked LP tokens",
        error: (data) => data,
      });

    toast.promise(onUnstake(unstakeInput), {
      loading: "Transaction is in progress",
      success: "Succesfully unstaked LP tokens",
      error: (data) => data,
    });
  };
  return (
    <>
    {(eligibleVotes > 0 & vote_enabled & Date.now()/1000 < deadline ) && 
      <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 justify-center py-2 text-foreground/50">
        <h2>You still have {parseFloat(eligibleVotes).toFixed(0)} votes to decide on the pending proposal!</h2>
      </div>}
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8">
      <div className="flex justify-center gap-2 w-full">
        <Button
          onClick={onStakeInput}
          className={clsx("text-foreground", {
            "bg-primary": isStake,
            "bg-primary/50": !isStake,
          })}
        >
          Stake
        </Button>
        <Button
          onClick={onUnstakeInput}
          className={clsx("text-foreground", {
            "bg-primary": !isStake,
            "bg-primary/50": isStake,
          })}
        >
          unStake (5% LP FEE)
        </Button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mt-8 p-1 pr-0">
          <div className="flex w-full">
            <input
              value={isStake ? stakeInput : unstakeInput}
              max={isStake ? balance : stakeBalance}
              onChange={onInputChange}
              placeholder={placeholder}
              className="text-foreground font-medium w-[80%] rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />

            <span className="w-1/5">
              <Button
                type="button"
                onClick={onMaxBalance}
                className="rounded-tl-none rounded-bl-none"
              >
                max
              </Button>
            </span>
          </div>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
        <table>
            <tr>
              <td>
                <p><b>Unstaked LP Tokens</b> </p> 
              </td>
              <td>
              <p>{parseFloat(balance).toFixed(2)}</p>
              </td>
            </tr>
            <tr>
            <td>
                <p><b>Staked LP Tokens</b> </p> 
            </td>
              <td>
              <p>{parseFloat(stakeBalance).toFixed(2)}</p>
              </td>
            </tr>
          {!isStake && <>
          <tr><td><br /></td></tr>
            <tr>
            <td>
                <p>{!isStake && <b>Harvest Points Fee   </b>} </p> 
              </td>
            <td>
              <p>10%</p>
            </td>
            </tr>
            </>
          }
          </table>
          </div>
          {/*<p><b>CRB balance:</b> {parseFloat(balance).toFixed(2)}</p>
        </div>
        <div className="flex justify-center text-foreground/50">
        <p><b>Staked balance:</b> {parseFloat(stakeBalance).toFixed(2)} </p>*/}
        {/*<div className="flex justify-center text-foreground/50"><p>{!isStake && <p><b>Unstake fee (+5 harvest points):</b> {parseFloat(unstakeInput*5/100).toFixed(2)}</p>}</p></div>
        <div className="flex justify-center text-foreground/50"><p>{!isStake && <p><b>Unstake amount:</b> {(parseFloat(unstakeInput)-parseFloat(unstakeInput*5/100)).toFixed(2)}</p>}</p></div>
        <div className="flex justify-center py-2 text-foreground/50"></div>*/}
        <div className="border-t-2 border-foreground/30" />

        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
          {isConnected && <Button ref={submitButtonRef} type="submit">Stake</Button>}
          
        </div>
      </form>
    </div>
    </>
  );
};
