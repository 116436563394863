import React, { useEffect, useRef } from "react";
import { useNav } from "@/src/hooks";
import clsx from "clsx";
import { Link, NavLink } from "@/src/components";
import { socialLinks } from "./social";
import { navLinks } from "./nav";
import { useAccount } from "wagmi";
import { shortenAddress } from "@/src/lib/address";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import logo from "./Reborn_logo_2.png";

export const Sidebar = () => {
  const { isOpen, isMobile, onClose } = useNav();
  const { address } = useAccount();

  const { isConnected } = useAccount();
  const { onUseFaucet } = useStake();
  const { onNftFaucet } = useStake();

  const handleFaucet = async () => {
    if (!isConnected) return;

    toast.promise(onUseFaucet(), {
      loading: "Transaction is in progress",
      success: "Transaction successfully completed",
      error: (data) => data,
    });
  };
  const handleNftFaucet = async () => {
    if (!isConnected) return;

    toast.promise(onNftFaucet(), {
      loading: "Transaction is in progress",
      success: "Transaction successfully completed",
      error: (data) => data,
    });
  };
  const modalRef = useRef(null);

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (!isMobile) return;
      const buttonEle = document.getElementById("modal-button");

      if (buttonEle?.contains(event.target)) return;
      if (modalRef.current?.contains(event.target)) return;
      onClose();
    };

    document.addEventListener("click", onDocumentClick);

    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, [isMobile, onClose]);

  return (
    <aside
      ref={modalRef}
      className={clsx(
        "w-64 lg:h-screen min-w-[300px] flex flex-col h-screen bg-background text-foreground fixed lg:sticky  bottom-0 top-0 overflow-y-auto p-8  z-50",
        {
          "animate-content-show left-0": isOpen,
          "animate-content-hide -left-96": !isOpen,
        }
      )}
    >
      <h2 className="font-bold text-4xl">
      <div className="flex justify-center"><img src={logo} style={{width:"60%", height:"60%"}} /></div></h2>

      {/*<div className="border-y border-foreground/10 mt-8 py-3">
        <h2 className="font-medium">Your Wallet Address:</h2>
        <p className="text-foreground/60">
          {shortenAddress(address) || "NOT CONNECTED"}
        </p>
      </div>*/}
      <div className="gap-2">
      <div className="border-y border-foreground/10 mt-8 py-2">
        <h2 className="font-medium"></h2>
        <p className="font-small text-foreground/60">
        <a target="_blank" href="https://app.blazeswap.xyz/swap/?inputCurrency=0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d&outputCurrency=0xAAF2eEa8D701EA7d6d328C6a60c4032C9c301EE6">
        <Link>Swap</Link>
        </a>
        </p>
      </div>
      <div className="border-y border-foreground/10 mt-8 py-2">
        <h2 className="font-medium"></h2>
        <p className="font-small text-foreground/60">
        <a target="_blank" href="https://app.blazeswap.xyz/pool/add/?tokenA=0x12e605bc104e93B45e1aD99F9e555f659051c2BB&tokenB=0xAAF2eEa8D701EA7d6d328C6a60c4032C9c301EE6">
        <Link>Manage Liquidity</Link>
        </a>
        </p>
      </div>
      
      {/*<div className="border-y border-foreground/10 mt-8 py-2">
        <h2 className="font-medium"></h2>
        <p className="font-small text-foreground/60">
        <a target="_blank" href="https://dex.oracleswap.io/swap">
        <Link>OracleSwap</Link>
        </a>
        </p>
    </div>
      <div className="border-y border-foreground/10 mt-8 py-2">
        <h2 className="font-medium"></h2>
        <p className="font-small text-foreground/60">
        <a target="_blank" href="https://www.xenosfinance.xyz/liquidity">
        <Link>Xenos Finance</Link>
        </a>
        </p>
    </div>*/}
      </div>
      <div className="mt-10 grid gap-5">
        {navLinks.map(({ icon, href, label }) => (
          <NavLink icon={icon} href={href} key={label}>
            {label}
          </NavLink>
        ))}
      </div>

      <div className="flex flex-col gap-8 mt-auto pt-10">
        <div className="h-0.5 bg-foreground/10 w-full" />
        <div className="gap-4 grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4">
          {socialLinks.map(({ icon, href }, index) => (
            <Link key={index} href={href}>
              {icon}
            </Link>
          ))}
        </div>
      </div>
    </aside>
  );
};
