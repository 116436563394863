import { FileAdd } from "@/src/icons";

export const navLinks = [
  {
    icon: "",
    label: "Harvest",
    href: "/harvest",
  },
  {
    icon: "",
    label: "Charging Station",
    href: "/charge",
  },
  {
    icon: "",
    label: "Rewards",
    href: "/rewards",
  },
  {
    icon: "",
    label: "Vote",
    href: "/DaoVote",
  },
  {
    icon: "",
    label: "Docs",
    href: "https://docs.canary-reborn.xyz",
    target: "_blank",
  }
];
